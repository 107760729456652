<template>
  <page-layout>
    <div class="wrapper dashboard">
      <div class="title-wrap">
        <h3 class="page-titles">
          Resources
        </h3>

        <div class="profile">
          <the-profile class="print:hidden" />
        </div>
      </div>

      <div class="rounded-3xl bg-white dashboard-card p-12">
        <div
          v-for="(videoType, typeIndex) in Object.keys(availableVideos)"
          :key="`type ${typeIndex}`"
        >
          <h4 class="page-titles mt-12">
            {{ videoType }}
          </h4>
          <div class="flex mobile-flex justify-between">
            <div class="flex w-full flex-wrap">
              <div
                v-for="(video, index) in videos[videoType]"
                :key="`video ${index}`"
                class="w-1/3 p-4 inline-block"
              >
                <div
                  class="card bg-base-100 shadow-xl"
                  style="width: 100%;"
                >
                  <div class="card-body">
                    <h6 class="video-title">
                      {{ video.title }}
                    </h6>
                  </div>
                  <figure>
                    <iframe
                      class="video-content"
                      :src="`https://www.youtube.com/embed/${video.id}`"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hasTeamSubscription">
          <h4 class="page-titles mt-12">
            Supporting documents
          </h4>
          <div
            v-for="(name, documentIndex) in supportingDocuments"
            :key="`document ${documentIndex}`"
            class="w-1/3 p-4"
          >
            <div
              @click="downloadDocument(name)"
              class="flex flex-row cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
              <a
                class="underline underline-offset-8"
              >
                {{ name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout.vue'
import TheProfile from '@/components/TheProfile.vue'

import download from 'downloadjs'
import axios from 'axios'

export default {
  components: {
    PageLayout,
    TheProfile
  },
  data () {
    return {
      hasTeamSubscription: this.$store.state.USER.user.termRemainingTeams !== null,
      videos: {
        'Demo videos': [
          { title: 'Administrate test & reports', id: '-PXsacwgebk' },
          { title: 'Setup a new test', id: 'rNourzoow5s' },
          { title: 'Cognitive requirement specification', id: 'nk880v6q-Vo' },
          { title: 'Scoreboard (Sort, match & filter candidates)', id: 'pTKzMQUs1V4' },
          { title: 'Understand the individual report', id: 'Qmkf-5w4YB4' }
        ],
        Training: [
          { title: 'A framework for how to use the result', id: 'ok42xGE0zZQ' },
          { title: 'Review of different Cognitive Profiles', id: '0x6Vxa1WsSE' },
          { title: 'Contextualizing the Cognitive Profile', id: 'kHRpIE0HsoE' },
          { title: 'Action plan', id: 'blorqxjKhJw' }
        ],
        'Generic Cognitive Profile Report': [
          { title: 'Introduction', id: 'dtaMobgHlQ0' },
          { title: 'Abilites Measured', id: 'RHaM99kMP1I' },
          { title: 'Cognitive Profile', id: 'ox9Bl3Jloac' },
          { title: 'Quality measures', id: '4SnEi87WXzQ' },
          { title: 'Self Awareness', id: 'mRtv-TX4lmA' },
          { title: 'Performance Under Pressure', id: 'abzjR-ZuAwI' }
        ]
      },
      supportingDocuments: [
        'About Cognitive Team Profiling',
        'Checklist for feedback of test result',
        'Process Cognitive Team Profiling and development',
        'Team core questions and reflections'
      ]
    }
  },
  computed: {
    availableVideos () {
      return this.hasTeamSubscription ? this.videos : { 'Demo videos': this.videos['Demo videos'] }
    }
  },
  methods: {
    async downloadDocument (name) {
      const url = await this.$api.getDocumentDownloadUrl(name)
      try {
        const { data, headers } = await axios.get(url, {
          responseType: 'blob'
        })
        const contentType = headers['content-type']
        download(data, `${name}.pdf`, contentType)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss">
@import "../demovideos.scss";
</style>
